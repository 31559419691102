import React from 'react';
import styled from 'styled-components';
import { richTextContent, button } from '../../styles/utilities/elements';
import RichText from '../contentBlocks/RichText';
import LinksContainer, { SLinksContainer } from './LinksContainer';
import { colors } from '../../styles/utilities/settings';
import PageLink from '../helpers/PageLink';

const HeadingContent = ({
  hero,
  centered,
  nav,
  content: { heading, links, copy, paragraph, user, date },
  children,
}) => (
  <HeadingContainer centered={centered}>
    {hero ? (
      <h1>
        {nav ? (
          <ul>
            {nav.map((item, index) => (
              <li key={item._key}>
                <PageLink
                  content={{
                    copy:
                      nav.length === index + 1
                        ? `& ${item.link.copy}`
                        : item.link.copy,
                    url: item.link.url,
                  }}
                />
              </li>
            ))}
          </ul>
        ) : (
          <>
            {heading}
            {children}
          </>
        )}
      </h1>
    ) : (
      <h2>
        {heading}
        {children}
      </h2>
    )}
    {user && <p>{`by ${user}`}</p>}
    {date && <p>{`published on ${date}`}</p>}
    {copy && <RichText content={{ copy, centered: true }} />}
    {paragraph && <p>{paragraph}</p>}
    {links && <LinksContainer content={links} />}
  </HeadingContainer>
);

export default HeadingContent;

export const HeadingContainer = styled.div`
  position: relative;
  z-index: 1;
  ${richTextContent};
  ${({ centered }) => centered && 'text-align: center;'}

  ${SLinksContainer} {
    a {
      color: ${colors.white};
      margin-top: 20px;
      ${button};
    }
  }
`;
